import { isPlatformServer } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { Business } from 'src/app/models/business';
import { Order } from 'src/app/models/order';
import { PaymentLink } from 'src/app/models/payment-link';
import { Product } from 'src/app/models/product';
import { Settings } from 'src/app/models/settings';
import { ShoppingBag } from 'src/app/models/shopping-bag';
import { ApiService } from 'src/app/services/api.service';
import { LocalService } from 'src/app/services/local.service';
import { NotyfService } from 'src/app/services/notyf.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  readonly searchSubject = new Subject<string | undefined>();
  searchSubscription?: Subscription;
  @ViewChild('search', { static: false })
  inputElement!: ElementRef;
  animateClose = false;
  shoppingBag!: ShoppingBag;
  loading = false;
  business!: Business;
  settings!: Settings;
  searching = false;
  products: Product[] = [];
  @Output() closeEmit = new EventEmitter<void>();

  constructor(
    private local: LocalService,
    private router: Router,
    private notyf: NotyfService,
    private api: ApiService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(300),
        switchMap(async (query: any) =>
          (await query) ? this.search(query) : ''
        )
      )
      .subscribe((results: Product[]) => {
        this.products = results;
        if (Array.isArray(this.products)) {
          this.searching = false;
        } else {
          this.products = [];
          this.searching = false;
        }
      });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 0);
  }
  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

  closeSearch(): void {
    this.animateClose = true;
    setTimeout(() => {
      this.closeEmit.emit();
    }, 250);
  }
  async doSearch(query: string) {
    try {
      this.searching = true;
      await this.searchSubject.next(query?.trim());
    } catch (error) {
      this.notyf.error(error);
      this.searching = false;
    }
  }
  goToProduct(id: string) {
    this.router.navigate(['/productos/' + id]);
    this.closeSearch();
  }
  async search(query: any) {
    try {
      return await this.api.searchProducts(
        query,
        this.local.getValue('inventory')?.id
      );
    } catch (error) {
      this.notyf.error(
        error,
        'Ha ocurrido un error tratando de hacer la búsqueda. Intenta nuevamente ✌️.'
      );
    }
  }
}
