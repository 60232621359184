<div
  class="w-full border border-slate-300 rounded-xl flex flex-col transition-all duration-100 pb-4 select-none"
>
  <div
    class="w-full flex justify-between items-center cursor-pointer px-4 pt-4"
    (click)="styler.toggleFilter('sort')"
  >
    <span class="font-bold text-sm sm:text-base">Ordenar</span>
    <i
      class="fas fa-chevron-right text-primary transition-all duration-100 ease-in"
      id="sort-arrow"
    ></i>
  </div>
  <div
    id="sort-panel"
    class="w-full overflow-hidden transition-all ease-in duration-200 px-4 flex flex-col gap-2"
  >
    <div class="text-sm sm:text-base flex items-center gap-2 pt-2">
      <div class="radio-form-control">
        <input
          type="radio"
          (click)="close.emit()"
          class="hover:cursor-pointer"
          [id]="'1'"
          [name]="'sort'"
          [value]="'default'"
          [(ngModel)]="sort"
          checked
          [routerLink]="['/productos']"
          [queryParams]="{ cid: cid, sort: 'default' }"
        />
      </div>
      <label [for]="'1'" class="hover:cursor-pointer">
        <span class="text-sm sm:text-base">Relevancia</span>
      </label>
    </div>
    <div class="text-sm sm:text-base flex items-center gap-2 pt-2">
      <div class="radio-form-control">
        <input
          type="radio"
          class="hover:cursor-pointer"
          (click)="close.emit()"
          [id]="'2'"
          [name]="'sort'"
          [value]="'mayor'"
          [(ngModel)]="sort"
          [routerLink]="['/productos']"
          [queryParams]="{ cid: cid, sort: 'mayor' }"
        />
      </div>
      <label [for]="'2'" class="hover:cursor-pointer">
        <span class="text-sm sm:text-base">Precio mayor a menor</span>
      </label>
    </div>
    <div class="text-sm sm:text-base flex items-center gap-2 pt-2">
      <div class="radio-form-control">
        <input
          type="radio"
          class="hover:cursor-pointer"
          (click)="close.emit()"
          [id]="'3'"
          [name]="'sort'"
          [value]="'menor'"
          [(ngModel)]="sort"
          [routerLink]="['/productos']"
          [queryParams]="{ cid: cid, sort: 'menor' }"
        />
      </div>
      <label [for]="'3'" class="hover:cursor-pointer">
        <span class="text-sm sm:text-base">Precio menor a mayor</span>
      </label>
    </div>
  </div>
</div>
