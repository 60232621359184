import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SwalService {
  swal = Swal;
  constructor() {}

  showDialog(title: string, message: string, type: string) {
    return this.swal.fire({
      title: title,
      html: message,
      icon: type as SweetAlertIcon,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      reverseButtons: true,
      showCancelButton: true,
      showConfirmButton: true,
      customClass: {
        container: 'font-primary',
        confirmButton:
          '!bg-black !text-white !button !outline-none focus:!shadow-none',
        cancelButton:
          '!bg-white !text-black !button !border !border-black !border-solid',
      },
    });
  }

  showInfo(title: string, message: string) {
    return this.swal.fire({
      title: title,
      html: message,
      icon: 'info' as SweetAlertIcon,
      customClass: {
        container: 'font-primary',
        confirmButton:
          '!bg-black !text-white !button !outline-none focus:!shadow-none',
      },
    });
  }

  showSuccess(title: string, message: string) {
    return this.swal.fire({
      title: title,
      html: message,
      icon: 'success' as SweetAlertIcon,
      customClass: {
        container: 'font-primary',
        confirmButton:
          '!bg-black !text-white !button !outline-none focus:!shadow-none',
      },
    });
  }

  showError(title: string, message: string) {
    return this.swal.fire({
      title: title,
      html: message,
      icon: 'error' as SweetAlertIcon,
      customClass: {
        container: 'font-primary',
        confirmButton:
          '!bg-black !text-white !button !outline-none focus:!shadow-none',
      },
    });
  }
}
