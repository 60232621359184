<div class="flex flex-col " *ngIf="!loading && category">
    <div class="flex items-center justify-between py-8">
        <span class="text-xl font-bold text-black">{{category.name}}</span>
        <a [routerLink]="['/productos']" [queryParams]="{cid: category._id}"
            class="rounded-md bg-transparent text-black font-bold text-sm py-2 px-4 border-2 border-black">Ver
            categoría</a>
    </div>
    <swiper-container swiperElement [config]="config" init="false" class="w-full pb-8">
        <swiper-slide *ngFor="let product of categoryProducts">
            <app-carousel-product-card [product]="product"></app-carousel-product-card>
        </swiper-slide>
    </swiper-container>
</div>