const minutesBetweenDates = (
  initialMiliseconds: number,
  finalMiliseconds: number
): number => {
  const miliseconds = finalMiliseconds - initialMiliseconds;
  const seconds = miliseconds / 1000;
  const minutes = seconds / 60;
  return minutes;
};

export default minutesBetweenDates;
