import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteItem } from 'src/app/models/route';

@Component({
  selector: 'app-custom-navigation',
  templateUrl: './custom-navigation.component.html',
  styleUrls: ['./custom-navigation.component.scss']
})
export class CustomNavigationComponent {

  @Input() items!: RouteItem[] | undefined;

  constructor(private router: Router) {}

  navigateRoute(route: RouteItem) {
    if(route.type === 'CATEGORY') {
      this.router.navigate(['/productos'], { queryParams: { cid: route.link }});
    } else {
      window.location.href = route.link;
    }
  }

}
