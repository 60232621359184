import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Design } from 'src/app/models/design';
import { Inventory } from 'src/app/models/inventory';
import { SupportContact } from 'src/app/models/support-contact';
import { ApiService } from 'src/app/services/api.service';
import { NotyfService } from 'src/app/services/notyf.service';

@Component({
  selector: 'app-disabled',
  templateUrl: './disabled.component.html',
  styleUrls: ['./disabled.component.scss'],
})
export class DisabledComponent implements OnInit {
  design!: Design;
  contacts: SupportContact[] = [];
  inventories: Inventory[] = [];
  loading = true;
  constructor(
    private api: ApiService,
    private notyf: NotyfService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    this.loadLocalData();
    this.loadAsyncData();
  }

  loadLocalData() {
    if (isPlatformBrowser(this.platformId)) {
      this.design = JSON.parse(
        window.localStorage.getItem('design') as string
      ) as Design;
    }
  }

  async loadAsyncData() {
    try {
      this.inventories = await this.api.getActiveInventories();
      if (this.inventories.length > 0) {
        // for (const inventory of this.inventories) {
        //   const contact = await this.api.getSupportContactOfInventory(
        //     inventory.id
        //   );
        //   this.contacts.push(contact);
        // }
      }
      this.loading = false;
    } catch (error) {
      this.notyf.error(error);
      this.loading = false;
    }
  }
}
