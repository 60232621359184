<div
  class="w-full border border-slate-300 rounded-xl flex flex-col transition-all duration-100 pb-4 select-none"
>
  <div
    class="w-full flex justify-between items-center cursor-pointer px-4 pt-4"
    (click)="styler.toggleFilter('category')"
  >
    <span class="font-bold text-sm sm:text-base">Filtros</span>
    <i
      class="fas fa-chevron-right text-primary transition-all duration-100 ease-in"
      id="category-arrow"
    ></i>
  </div>
  <div
    id="category-panel"
    class="w-full overflow-hidden transition-all ease-in duration-200 px-4 flex flex-col gap-2"
  >
    <div class="text-sm sm:text-base flex items-center gap-2 pt-2">
      <div class="radio-form-control">
        <input
          type="radio"
          (click)="close.emit()"
          class="hover:cursor-pointer"
          [id]="'0'"
          [name]="'category'"
          [value]="''"
          [(ngModel)]="cid"
          [routerLink]="['/productos']"
          [queryParams]="{ cid: '', sort: sort }"
        />
      </div>
      <label [for]="'0'" class="hover:cursor-pointer">
        <span class="text-sm sm:text-base">Todos</span>
      </label>
    </div>
    <div
      *ngFor="let category of categories"
      class="text-sm sm:text-base flex gap-2 items-center"
    >
      <div class="radio-form-control">
        <input
          type="radio"
          class="hover:cursor-pointer"
          (click)="close.emit()"
          [id]="category._id"
          [name]="'category'"
          [value]="category._id"
          [(ngModel)]="cid"
          [routerLink]="['/productos']"
          [queryParams]="{ cid: category._id, sort: sort }"
        />
      </div>
      <label [for]="category._id" class="hover:cursor-pointer">
        <span class="text-sm sm:text-base capitalize">{{ category.name | titlecase}}</span>
      </label>
    </div>
  </div>
</div>
