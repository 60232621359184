import { isPlatformServer } from "@angular/common";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { Business } from "../models/business";
import { Inventory } from "../models/inventory";
import { Settings } from "../models/settings";
import { ApiService } from "../services/api.service";
import { LocalService } from "../services/local.service";
import { NotyfService } from "../services/notyf.service";
import { StateService } from "../services/state.service";
import minutesBetweenDates from "../utils/minutesBetweenDates";
interface MixPanelData {
  distinctId: string;
  timestamp: number;
}
@Injectable({
  providedIn: "root",
})
export class AccessGuard {
  inventories!: Inventory[];
  settings!: Settings[];
  business!: Business;
  constructor(
    private api: ApiService,
    private local: LocalService,
    private router: Router,
    private stateService: StateService,
    private notyf: NotyfService,
    @Optional() @Inject("schema_id") private schema: number,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  loadLocalData(
    business: Business,
    inventories: Inventory[],
    allSettings: Settings[],
  ) {
    if (!localStorage.getItem("frontOffice")) {
      window.localStorage.setItem("frontOffice", JSON.stringify({}));
    }
    if (!this.local.getValue("shoppingBag")) {
      const shoppingBag = {
        orders: [],
        timestamp: new Date().getTime(),
      };
      this.local.setValue("shoppingBag", shoppingBag);
    }
    let currentInventory = this.local.getValue("inventory") as Inventory;
    for (const inventory of inventories) {
      inventory.delivery_zones = [];
    }
    this.local.setValue("inventories", inventories);
    this.local.setValue("business", business);
    if (inventories.length > 0) {
      /**
       *  Si no tiene inventario en cache
       *  -- Si tiene varios, mandar a venues
       *  -- Si tiene uno solo, setear el primero del array y setear settings.
       * Si el inventario se expiro
       *  -- Si tiene varios, mandar a venues
       *  -- Si tiene uno solo, setear el primero del array y setear settings
       */
      if (currentInventory === null || currentInventory === undefined) {
        if (inventories.length > 1) {
          this.router.navigate(["/sedes"]);
          return false;
        } else {
          currentInventory = {
            ...inventories[0],
            timestamp: Date.now(),
          };
          const settings = allSettings.filter(
            (setting) => setting.inventory_id === currentInventory.id,
          )[0];
          this.local.setValue("inventory", currentInventory);
          this.local.setValue("settings", settings);
          return true;
        }
      } else if (
        minutesBetweenDates(currentInventory.timestamp, Date.now()) > 120
      ) {
        if (inventories.length > 1) {
          this.router.navigate(["/sedes"]);
          return false;
        } else {
          currentInventory = {
            ...inventories[0],
            timestamp: Date.now(),
          };
          const settings = allSettings.filter(
            (setting) => setting.inventory_id === currentInventory.id,
          )[0];
          this.local.setValue("inventory", currentInventory);
          this.local.setValue("settings", settings);
          return true;
        }
      } else {
        const settings = allSettings.filter(
          (setting) => setting.inventory_id === currentInventory.id,
        )[0];
        this.local.setValue("settings", settings);
        return true;
      }
    } else {
      /** No tiene inventarios activos */
      this.router.navigate(["/cerrado"]);
      return false;
    }
  }

  async loadAsyncData() {
    try {
      this.inventories = await this.api.getActiveInventories();
      this.business = await this.api.getBusiness();
      this.settings = await this.api.getAllSettings();
      const inventoriesKey = this.stateService.createKey("inventories");
      const businessKey = this.stateService.createKey("business");
      const settingsKey = this.stateService.createKey("settings");
      this.stateService.setState(inventoriesKey, this.inventories);
      this.stateService.setState(businessKey, this.business);
      this.stateService.setState(settingsKey, this.settings);

      return true;
    } catch (error) {
      console.error(error);
      this.notyf.error(error);
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platformId)) {
      const schemaKey = this.stateService.createKey("schema");
      this.stateService.setState(schemaKey, this.schema);
      return this.loadAsyncData();
    } else {
      const inventoriesKey = this.stateService.createKey("inventories");
      const businessKey = this.stateService.createKey("business");
      const settingsKey = this.stateService.createKey("settings");
      if (this.stateService.getState(businessKey).length === 0) {
        return this.loadAsyncData().then(() => {
          this.business = JSON.parse(this.stateService.getState(businessKey));
          this.inventories = JSON.parse(
            this.stateService.getState(inventoriesKey),
          );
          this.settings = JSON.parse(this.stateService.getState(settingsKey));
          if (this.business.disabled) {
            /** Si la tienda esta deshabilitada */
            this.router.navigate(["/cerrado"]);
            return false;
          }
          return this.loadLocalData(
            this.business,
            this.inventories,
            this.settings,
          );
        });
      } else {
        this.business = JSON.parse(this.stateService.getState(businessKey));
        this.inventories = JSON.parse(
          this.stateService.getState(inventoriesKey),
        );
        this.settings = JSON.parse(this.stateService.getState(settingsKey));
        if (this.business.disabled) {
          /** Si la tienda esta deshabilitada */
          this.router.navigate(["/cerrado"]);
          return false;
        }
        return this.loadLocalData(
          this.business,
          this.inventories,
          this.settings,
        );
      }
    }
  }
}
