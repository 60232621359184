<div
  [ngClass]="{
    'animate-menuClose': animateClose,
    'animate-menuOpen': !animateClose
  }"
  class="flex flex-col overflow-y-auto w-full real-h absolute max-w-full top-0 right-0 z-50 bg-white !rounded-none !p-0"
>
  <div class="flex justify-start items-center">
    <button
      (click)="closeMenu()"
      class="bg-transparent text-primary py-6 px-8 text-2xl"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="flex flex-col mx-14 h-full items-start justify-start">
    <!-- Venues selector -->
    <div class="select-wrapper w-full mb-12">
      <select
        name="selectedInventory"
        (ngModelChange)="inventoryChange($event)"
        class="w-full border border-black"
        [(ngModel)]="selectedInventory"
      >
        <option *ngFor="let inventory of inventories" [ngValue]="inventory">
          📍 {{ inventory.name }}
        </option>
      </select>
    </div>

    <!-- Router Links -->
    <ng-container *ngIf="!selectedRoute">
      <div class="w-full h-full animate-fadeIn">
        <a
          (click)="closeMenu()"
          [routerLink]="['/']"
          class="my-4 text-2xl font-bold underline text-black"
          >Inicio</a
        >
        <!-- Product dropdown -->
        <button
          class="bg-transparent font-bold text-2xl flex items-center justify-between w-full"
          *ngIf="!business.navigationActive"
          (click)="productsOpen = !productsOpen"
        >
          <span class="underline">Productos</span>
          <i
            class="fas fa-chevron-right text-primary transition-all duration-100 ease-in"
            [ngClass]="{ 'rotate-90': productsOpen, '': !productsOpen }"
          ></i>
        </button>
        <ng-container *ngIf="business.navigationActive">
          <button
            class="bg-transparent my-4 font-bold flex items-center justify-between w-full"
            (click)="setRoute(route)"
            *ngFor="let route of routes"
          >
            <span class="underline text-2xl" style="text-align: left">{{
              route.name
            }}</span>
            <i class="fas fa-chevron-right" *ngIf="route.items.length > 0"></i>
          </button>
        </ng-container>
        <div
          class="flex flex-col mx-2 my-2 w-full"
          *ngIf="productsOpen && !business.navigationActive"
        >
          <a
            (click)="closeMenu()"
            [routerLink]="['/productos']"
            class="w-full text-black text-lg my-2"
            >Todos</a
          >
          <a
            (click)="closeMenu()"
            [routerLink]="['/productos']"
            [queryParams]="{ cid: cat._id }"
            class="w-full text-black text-lg my-2"
            *ngFor="let cat of categories"
            >{{ cat.name }}</a
          >
        </div>
        <!-- Router Links -->
        <a
          (click)="closeMenu()"
          [routerLink]="['/contacto']"
          class="my-4 text-2xl font-bold underline text-black"
          >Contacto</a
        > <br />
        <!-- Whatsapp Link -->
        <a
          (click)="closeMenu()"
          class="text-xl font-bold text-black mt-auto pb-8 underline"
          [href]="'https://wa.me/' + contact.phone_number"
          href=""
          target="_blank"
        >
          <i class="fab fa-whatsapp mr-2"></i>
          {{ contact.phone_number }}</a
        >
      </div>
    </ng-container>

    <ng-container *ngIf="selectedRoute">
      <div class="animate-fadeIn w-full">
        <div
          class="bg-transparent mb-8 font-bold flex items-center justify-center w-full"
        >
          <button
            class="bg-transparent pr-6 py-3"
            (click)="goBackRoute(selectedRoute)"
          >
            <i class="fas fa-chevron-left"></i>
          </button>
          <div
            class="text-2xl py-3 bg-transparent w-full hover:cursor flex flex-start"
            (click)="navigateRoute(selectedRoute)"
          >
            <span class="underline" style="text-align: left">{{
              selectedRoute.name
            }}</span>
          </div>
        </div>
        <div class="flex" *ngFor="let innerItem of selectedRoute.items">
          <button
            class="text-2xl py-3 bg-transparent w-full flex items-center justify-between"
            (click)="setRoute(innerItem)"
          >
            <span class="text-2xl" style="text-align: left">{{
              innerItem.name
            }}</span>
            <i
              class="fas fa-chevron-right"
              *ngIf="innerItem.items.length > 0"
            ></i>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
