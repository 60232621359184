import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Settings } from '../models/settings';
import { ShoppingBag } from '../models/shopping-bag';
import { SupportContact } from '../models/support-contact';
import { ApiService } from '../services/api.service';
import { LocalService } from '../services/local.service';
import { NotyfService } from '../services/notyf.service';
import { Business } from '../models/business';
import { Route } from '../models/route';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  settings = {} as Settings;
  contact = {} as SupportContact;
  loading = true;
  shoppingBag = {} as ShoppingBag;
  quantitySubscription!: Subscription;
  bagOpen = false;
  isDesktop = false;
  hideButtons = false;
  business!: Business;
  navigation!: Route | undefined;
  constructor(
    private local: LocalService,
    private api: ApiService,
    private notyf: NotyfService,
    private router: Router,
    private route: ActivatedRoute,
    private bp: BreakpointObserver
  ) {
    this.bp
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        this.isDesktop = state.matches;
        this.listenToRoute();
      });
  }

  ngOnInit(): void {
    this.loadLocalData();
    this.loadAsyncData();
    this.quantitySubscription = this.local.quantitySubject.subscribe(
      (quantity) => {
        this.shoppingBag = this.local.getValue('shoppingBag');
      }
    );
  }
  async loadAsyncData() {
    try {
      this.contact = await this.api.getSupportContactOfInventory(
        this.local.getValue('inventory').id
      );
      this.business = await this.api.getBusiness();
      if(this.business.navigationActive) {
        const response = await this.api.getRoutes();
        this.navigation = response[0];
      }
      this.loading = false;
    } catch (error) {
      this.notyf.error(error);
    }
  }
  loadLocalData() {
    this.shoppingBag = this.local.getValue('shoppingBag');
    this.settings = this.local.getValue('settings');
  }
  getShoppingBagTotal() {
    let total = 0;
    this.shoppingBag.orders.forEach((order) => {
      total = total + order.price * order.quantity;
    });
    return total;
  }
  listenToRoute(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('/productos/')) {
          this.hideButtons = true;
        } else {
          this.hideButtons = false;
        }
      }
    });
  }
  closeShoppingBag() {
    this.bagOpen = false;
  }
}
