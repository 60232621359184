import { isPlatformServer } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Request } from 'express';
import { StateKey, Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { Business } from './models/business';
import { Design } from './models/design';
import { ApiService } from './services/api.service';
import { StateService } from './services/state.service';
import { SwalService } from './services/swal.service';
import { ThemeService } from './services/theme.service';
import { fade } from './utils/animations';
import { MixpanelService } from './services/mixpanel.service';
import makeString from './utils/randomString';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fade],
})
export class AppComponent {
  schemaKey: StateKey<string>;
  designKey: StateKey<string>;
  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];
  isBuilder = false;
  constructor(
    private meta: Meta,
    private titleService: Title,
    private api: ApiService,
    @Inject(PLATFORM_ID) platformId: Object,
    private stateService: StateService,
    private themeService: ThemeService,
    private router: Router,
    private swal: SwalService,
    private mixpanel: MixpanelService,
    @Optional() @Inject('schema_id') private schema: number,
    @Optional() @Inject('req') private req: Request
  ) {
    this.schemaKey = this.stateService.createKey('schema');
    this.designKey = this.stateService.createKey('design');
    if (isPlatformServer(platformId)) {
      if (this.schema !== null) {
        this.stateService.setState(this.schemaKey, this.schema);
        this.setupTags();
      }
    } else {
      this.isBuilder = this.inIframe();
      this.setupDesign();
      this.listenToRoute();
      document.documentElement.style.setProperty(
        '--measure',
        `${window.innerHeight}px`
      );
      window.addEventListener('resize', () => {
        document.documentElement.style.setProperty(
          '--measure',
          `${window.innerHeight}px`
        );
      });
      document
        .querySelector('body')
        ?.addEventListener('pointermove', this.preventDefault);
      this.checkOnlineStatus();
    }
  }

  ngAfterViewInit(): void {
    if (this.isBuilder) {
      this.listenToBuilderChanges();
    } else {
      const userToken = makeString(12);
      this.mixpanel.init(userToken);
    }
  }

  listenToBuilderChanges() {
    window.addEventListener('message', (event) => {
      const message = JSON.parse(JSON.stringify(event.data));
      if (event.origin.includes(location.hostname)) {
        // The data was sent from your site.
        // Data sent with postMessage is stored in event.data:
        if (message.design) {
          this.themeService.changeTheme(
            message.design.color_palette,
            message.design.background_url,
            message.design.font
          );
          if (window.localStorage)
            window.localStorage.setItem(
              'design',
              JSON.stringify(message.design)
            );
        }
        /** Primera carga */
        if (message.load) {
          return;
        } else {
          if (message.design) {
            /** Actualizaciones */
            window.location.reload();
          }
        }
      }
    });
  }
  preventDefault(e: any) {
    e.preventDefault();
  }
  inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  ngOnInit() {}

  setupDesign(): void {
    let favIcon: any = document.querySelector('#favIcon');
    const design = this.inIframe()
      ? this.themeService.getCachedTheme()
      : (JSON.parse(this.stateService.getState(this.designKey)) as Design);
    favIcon.href = design.logo_url;
    this.themeService.changeTheme(
      design.color_palette,
      design.background_url,
      design.font
    );
    if (window.localStorage)
      window.localStorage.setItem('design', JSON.stringify(design));
  }
  listenToRoute(): void {
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        if (
          val.url === '/' ||
          val.url.includes('/venues') ||
          val.url.includes('/shopping-bag') ||
          val.url.includes('/login') ||
          val.url.includes('/register') ||
          val.url.includes('/closed')
        ) {
          // this.local.deleteValue('checkout');
        }
      }
    });
  }

  async setupTags() {
    try {
      /** Setear design en state */
      const response = await this.api.getDesign();
      const design: Design = response[0];
      this.stateService.setState(this.designKey, design);
      /** Configurar todo el SEO de la tienda (Deberia suceder en el servidor) */
      const business: Business = await this.api.getBusiness();
      this.meta.addTag({ name: 'author', content: business.name });
      this.meta.addTag({ name: 'title', content: business.name });
      this.meta.addTag({
        name: 'description',
        content: 'Haz tu compra de forma rápida en nuestra tienda online.',
      });
      /** Facebook */
      this.meta.addTag({ name: 'og:type', content: 'website' });
      this.meta.addTag({
        name: 'og:url',
        content: this.req.headers['x-forwarded-host'] as string,
      });
      this.meta.addTag({ name: 'og:title', content: business.name });
      this.meta.addTag({
        name: 'og:description',
        content: 'Haz tu compra de forma rápida en nuestra tienda online.',
      });
      this.meta.addTag({ name: 'og:image', content: design.logo_url });
      /** Twitter */
      this.meta.addTag({
        name: 'twitter:card',
        content: 'summary_large_image',
      });
      this.meta.addTag({
        name: 'twitter:url',
        content: this.req.headers['x-forwarded-host'] as string,
      });
      this.meta.addTag({ name: 'twitter:title', content: business.name });
      this.meta.addTag({ name: 'twitter:description', content: '' });
      this.meta.addTag({ name: 'twitter:image', content: design.logo_url });
      this.titleService.setTitle(business.name);
    } catch (error) {
      console.error('error setting tags', error);
    }
  }

  checkOnlineStatus = () => {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(() => {}));
    this.subscriptions.push(
      this.offlineEvent.subscribe((val) => {
        this.swal.showDialog(
          'Ups',
          'Al parecer no cuentas con conexion de internet.',
          'error'
        );
      })
    );
  };
}
