import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Notyf } from 'notyf';
@Injectable({
  providedIn: 'root',
})
export class NotyfService {
  public notyf!: Notyf;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.notyf = new Notyf({
        position: { x: 'right', y: 'top' },
      });
    }
  }

  success(message: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.notyf.success(message);
    }
  }

  warning() {}

  error(error: any, message?: any) {
    if (isPlatformBrowser(this.platformId)) {
      console.error(error);
      // var ua = navigator.userAgent || navigator.vendor;
      // var isInstagram = ua.indexOf('Instagram') > -1 ? true : false;
      // if(isInstagram) {
      //   alert(JSON.stringify(error));
      // }
      // Sentry.captureException(error);
      this.notyf.error(message ? message : 'Ha ocurrido un error 😞');
    }
  }
  simpleError(message: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.notyf.error(message ? message : 'Ha ocurrido un error 😞');
    }
  }
}
