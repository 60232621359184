import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Design } from '../models/design';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) platformId: Object
  ) {}

  changeTheme(colors?: any[], image?: string, font?: string): void {
    if (font !== undefined) {
      this.doc.documentElement.style.setProperty('--font-family', font);
    }
    if (colors) {
      this.doc.documentElement.style.setProperty(
        '--detail-color',
        colors.filter((val) => val.type === 'detail')[0].value
      );
      this.doc.documentElement.style.setProperty(
        '--primary-color',
        colors.filter((val) => val.type === 'primary')[0].value
      );
      this.doc.documentElement.style.setProperty(
        '--secondary-color',
        colors.filter((val) => val.type === 'secondary')[0].value
      );
      this.doc.documentElement.style.setProperty(
        '--text-color',
        colors.filter((val) => val.type === 'text')[0].value
      );
    }
    if (image) {
      let value: string = 'url("' + image + '")';
      image = image
        .replace(
          'https://firebasestorage.googleapis.com/v0/b/wink-309217.appspot.com/',
          'https://ik.imagekit.io/d3ddbxom7/'
        )
        ?.concat('&tr=h-800');
      value = 'url("' + image + '")';
      this.doc.documentElement.style.setProperty('--background-image', value);
    } else {
      this.doc.documentElement.style.setProperty('--background-image', 'none');
    }
  }

  getCachedTheme(): Design {
    return JSON.parse(window.localStorage.getItem('design') as string);
  }
}
