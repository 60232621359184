<div class="page !overflow-y-auto bg-white pb-[100px]" (scroll)="onScroll($event)">
  <ng-container *ngIf="!loading; else skeleton">

    <div class="flex px-6 lg:px-8 relative gap-8">
      <div *ngIf="isDesktop && !loading" class="flex flex-col  !sticky h-fit top-0  w-[400px]">
        <span class="font-bold text-black text-xl w-full py-6">Filtrar y ordenar</span>
        <div class="gap-4 flex flex-col w-full">
          <app-category-filter [categories]="categories"></app-category-filter>
          <app-sort-filter></app-sort-filter>
        </div>
      </div>
      <div class="relative w-full">
        <!-- Product grid -->
        <div class="flex items-center justify-between w-full sticky top-0 z-10 bg-white py-6">
          <span class="font-bold text-black text-xl">{{categoryFilter === '' ? 'Todos los productos' :
            getCategoryName(categoryFilter)  | titlecase}}</span>
          <div *ngIf="!isDesktop && !loading" class="visible">
            <button (click)="menuOpen = true"
              class="rounded-md border border-inherits text-black font-bold  text-xl py-2 px-4    ">
              <i class="fa-solid fa-sliders  "></i>
            </button>
          </div>
        </div>
        <div class=" overflow-x-hidden w-full">
          <div *ngIf="!loading" id="products" class=" w-full  pb-[50px] grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10 justify-center
            lg:justify-start">
            <app-product-card *ngFor="let product of products" [product]="product" [page]="page" [cid]="categoryFilter" [sort]="sortingFilter" class="w-full  "></app-product-card>
          </div>
          <div class="flex items-center justify-center w-full" *ngIf="!isDesktop && loadingMoreProducts">
            <div class="h-12 w-12">
              <app-spinner [color]="'primary'"></app-spinner>
            </div>
          </div>
          <div class=" w-full flex justify-between items-center " *ngIf="isDesktop">
            <span>Mostrando {{first}}-{{second}} de {{productsCount}} productos</span>
            <div class="flex items-center rounded-xl overflow-hidden border ">
              <button [disabled]="page === 0" [ngClass]="{'bg-primary': true}" (click)="changePage(page-1,false)"
                class="text-white px-4 py-1 disabled:bg-slate-400 disabled:opacity-40">
                <i class="fa-sharp fa-solid fa-chevron-left"></i>
              </button>
              <span class=" px-4 py-1 border-slate-400">{{page +1 }}</span>
              <button [disabled]="page+1 === math.ceil(this.productsCount / 8)" [ngClass]="{'bg-primary': true}"
                (click)="changePage(page+1,true)" class="text-white  px-4 py-1 disabled:bg-slate-400 disabled:opacity-40">
                <i class="fa-sharp fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #skeleton>
    <div
      class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 lg:pr-10 xl:pr-30 relative overflow-hidden">
      <div class="  flex-col col-span-1 pl-8 pt-8 h-fit hidden lg:flex pr-8 gap-8">
        <div class="animate-pulse w-full h-[200px]  rounded-md bg-gray-200"></div>
        <div class="animate-pulse w-full h-[200px]  rounded-md bg-gray-200"></div>
      </div>
      <div class="sm:col-span-3 lg:col-span-2 xl:col-span-3  px-8 py-8 lg:pl-0 relative ">
        <div class="w-full pb-[50px] grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10 justify-center
            lg:justify-start">
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
          <div class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<app-side-filter *ngIf="menuOpen" (closeEmit)="menuOpen = false" [categories]="categories"></app-side-filter>