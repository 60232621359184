<div class="flex gap-32 w-full">
  <div class="flex flex-col" *ngFor="let item of items">
    <a
      class="text-lg font-bold mb-8"
      *ngIf="item.type === 'CATEGORY'; else external"
      [routerLink]="['/productos']"
      [queryParams]="{ cid: item.link }"
      >{{ item.name }}</a
    >
    <ng-template #external>
      <a [href]="item.link" class="text-lg font-bold mb-8">{{ item.name }}</a>
    </ng-template>
    <div class="flex gap-4" *ngFor="let innerItem of item.items">
      <a
        class="text-lg"
        *ngIf="innerItem.type === 'CATEGORY'; else innerExternal"
        [routerLink]="['/productos']"
        [queryParams]="{ cid: innerItem.link }"
        >{{ innerItem.name }}</a
      >
      <ng-template #innerExternal>
        <a [href]="innerItem.link" class="text-lg">{{ innerItem.name }}</a>
      </ng-template>
    </div>
  </div>
</div>
