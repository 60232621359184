import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@sentry/angular-ivy';
import { Subject } from 'rxjs';
import { Business } from '../models/business';
import { Inventory } from '../models/inventory';
import { Settings } from '../models/settings';
import { ShoppingBag } from '../models/shopping-bag';
import { SupportContact } from '../models/support-contact';
import { NotyfService } from './notyf.service';

interface FrontOffice {
  shoppingBag: ShoppingBag;
  inventory: Inventory;
  inventories: Inventory[];
  user: User;
  customer: number;
  business: Business;
  settings: Settings;
  contact: SupportContact;
}

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  quantitySubject: Subject<number> = new Subject<number>();
  cartSubject: Subject<ShoppingBag> = new Subject<ShoppingBag>(); //cantidad del carrito

  constructor(private router: Router, private notyf: NotyfService) {}

  setValue(key: string, data: any) {
    let frontOffice = JSON.parse(
      window.localStorage.getItem('frontOffice') as string
    );
    if (frontOffice) {
      frontOffice[key] = data;
      return window.localStorage.setItem(
        'frontOffice',
        JSON.stringify(frontOffice)
      );
    } else {
      this.clearValues();
    }
    // return localStorage.setItem('key', JSON.stringify(data));
  }

  getValue(key: string) {
    try {
      let frontOffice = JSON.parse(
        window.localStorage.getItem('frontOffice') as string
      );
      if (frontOffice) {
        if (frontOffice[key]) {
          return frontOffice[key];
        }
      } else {
        this.clearValues();
        return null;
      }
      // return JSON.parse(localStorage.getItem(key) as string);
    } catch (error) {
      console.error(error);
      this.notyf.error(error, error);
      this.clearValues();
      window.location.reload();
    }
  }

  clearValues() {
    window.localStorage.setItem('frontOffice', JSON.stringify({}));
    window.location.reload();
  }

  deleteValue(key: string) {
    let frontOffice = JSON.parse(localStorage.getItem('frontOffice') as string);
    if (frontOffice) {
      if (frontOffice[key]) {
        delete frontOffice[key];
      }
      window.localStorage.setItem('frontOffice', JSON.stringify(frontOffice));
    } else {
      this.clearValues();
    }
  }

  updateQuantitySubject(): void {
    let quantity: number = 0;
    let shoppingBag: ShoppingBag = this.getValue('shoppingBag');
    shoppingBag.orders.forEach((o) => {
      quantity = quantity + o.quantity;
    });
    this.quantitySubject.next(quantity);
  }

  updateCartSubject(): void {
    let bag: ShoppingBag = this.getValue('shoppingBag');
    this.cartSubject.next(bag);
  }
}
