<div [ngClass]="{
    'animate-cartClose': animateClose,
    'animate-cartOpen': !animateClose
  }" class="flex flex-col w-full max-w-xl overflow-y-auto real-h absolute top-0 right-0 z-50 bg-white !rounded-none !p-0">
    <!-- Search title header -->
    <div class="flex justify-between items-center py-6 " (click)="closeSearch()">
        <span class="text-2xl text-black font-bold pl-8">Búsqueda</span>
        <button class="bg-transparent text-primary text-2xl pr-8">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <!-- Search input -->
    <div class="flex  w-full h-[50px] px-8">
        <div class="search-wrapper h-full">
            <input autofocus #search type="text" class="input !rounded-r-none !pl-10 w-full h-full"
                (input)="doSearch(search.value)">
        </div>
        <button class=" !rounded-l-none border-b-2 border-solid border-secondary h-full !w-[60px]"
            (click)="search.value = ''; this.products = []">
            <i class="fa-solid fa-times"></i>
        </button>
    </div>
    <!-- Search results -->
    <div class="flex flex-col w-full gap-5 h-full overflow-scroll">
        <div class="w-full " *ngIf="!searching; else loading">
            <ng-container *ngFor="let product of products">
                <button (click)="goToProduct(product._id)" class="flex w-full gap-5 p-8 ">
                    <div class=" h-[80px] w-[30%] flex ">
                        <img *ngIf="product.image_url[0]" data-src="{{
                                product.image_url[0]
                                  .replace(
                                    'https://firebasestorage.googleapis.com/v0/b/wink-309217.appspot.com/',
                                    'https://ik.imagekit.io/d3ddbxom7/'
                                  )
                                  .concat('&tr=h-80')
                              }}" [alt]="product.name" data-sizes="auto" [id]="product._id"
                            [ngClass]="{ lazyload: true, show: true }" #image
                            class="h-[80px] w-[80px] object-contain lazyload" />
                    </div>
                    <div class="flex flex-col h-full w-[70%] text-start">
                        <span
                            class="text-black font-bold text-[14px] truncate w-full overflow-hidden text-ellipsis">{{product.name}}</span>
                        <span class="text-primary font-bold text-[14px] w-full">{{product.price | currency:
                            "USD":"symbol":undefined:"en-US"}}</span>
                        <p class="text-black text-[14px] overflow-hidden text-ellipsis w-full h-[24px] truncate">
                            {{product.description}}
                        </p>
                    </div>
                </button>
            </ng-container>
        </div>
        <ng-template #loading>
            <div class="flex items-center justify-center flex-col h-full w-full">
                <div class="h-28 w-28">
                    <app-spinner [color]="'primary'"></app-spinner>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<div (click)="closeSearch()" [ngClass]="{
    'animate-overlayClose': animateClose,
    'animate-overlay': !animateClose
  }" class="h-[100vh] w-full absolute z-10 bg-black opacity-40 top-0"></div>