import { style, animate, trigger, transition } from '@angular/animations';

export const fade = trigger('fade', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 })),
  ]),
]);

export const slide = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateX(100%)', opacity: 0 }),
    animate(
      '250ms ease-in',
      style({ transform: 'translateX(0%)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    animate('250ms ease-in', style({ transform: 'translateX(100%)' })),
  ]),
]);
