import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { DisabledComponent } from './pages/disabled/disabled.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductsComponent } from './pages/products/products.component';
import { VenuesComponent } from './pages/venues/venues.component';
import { AccessGuard } from './guards/access.guard';
import { DisabledGuard } from './guards/disabled.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: 'sedes',
    component: VenuesComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AccessGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'productos',
        component: ProductsComponent,
      },
      {
        path: 'productos/:id',
        component: ProductComponent,
      },
      {
        path: 'contacto',
        component: ContactComponent,
      },
    ],
  },
  {
    path: 'cerrado',
    component: DisabledComponent,
    // canActivate: [DisabledGuard],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
