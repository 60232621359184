import { Injectable } from '@angular/core';
import {
  TransferState,
} from '@angular/platform-browser';

import { StateKey, makeStateKey } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(private transferState: TransferState) {}

  createKey(name: string): StateKey<string> {
    const key = makeStateKey<string>(name);
    return key;
  }

  getState(key: StateKey<string>): string {
    return this.transferState.get(key, '');
  }

  setState(key: StateKey<string>, data: any): void {
    return this.transferState.set(key, JSON.stringify(data));
  }
}
