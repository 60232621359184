<div class="page !flex-row">
  <div class="hidden md:block bg-image h-full flex-1 brightness-50"></div>
  <div class="bg-secondary h-full flex-1 flex flex-col items-center justify-center p-8">
    <div class="card bg-white max-h-[450px] items-center justify-center gap-8 max-w-[480px] !py-8">
      <img data-src="{{
          design.logo_url
            .replace(
              'https://firebasestorage.googleapis.com/v0/b/wink-309217.appspot.com/',
              'https://ik.imagekit.io/d3ddbxom7/'
            )
            .concat('&tr=w-300')
        }}" id="header-logo" alt="Logo" class="w-[300px] max-h-[90px] object-contain" #image data-sizes="auto"
        [ngClass]="{ lazyload: true, show: image.complete }" />
      <button class="w-full bg-primary button" id="location-button" [disabled]="locationServiceDisabled || loading"
        (click)="getPosition()">
        Escoger sede más cercana
      </button>
      <span class="text-xs text-center font-bold" *ngIf="locationServiceDisabled">
        Activa los permisos de ubicación para poder encontrar la sede mas
        cercana a ti.
      </span>
      <hr class="w-full" />
      <ng-container *ngIf="inventories.length > 2; else venuesButtons">
        <div class="select-wrapper w-full" *ngIf="!loading && inventories">
          <select class="w-full bg-white text-black border border-primary" [(ngModel)]="selectedInventory"
            (ngModelChange)="selectStore($event)">
            <option [ngValue]="null" disabled>Selecciona una sede</option>
            <option *ngFor="let inventory of inventories" [ngValue]="inventory">
              {{ inventory.name }}
            </option>
          </select>
        </div>
      </ng-container>
      <ng-template #venuesButtons>
        <ng-container *ngIf="!loading && inventories">
          <button *ngFor="let inventory of inventories" (click)="selectStore(inventory)"
            class="button bg-primary text-white font-bold w-full">
            <span>{{ inventory.name }}</span>
          </button>
        </ng-container>
      </ng-template>
      <!-- <ng-container *ngFor="let inventory of inventories">
                <button class="bg-primary button" (click)="selectVenue(inventory)">{{inventory.name}}</button>
            </ng-container> -->
    </div>
  </div>
  <div class="w-full fixed bottom-0 bg-black text-white flex items-center justify-center">
    <span>Hecho con ❤️ por WINK</span>
  </div>
</div>