import { registerLocaleData } from '@angular/common';
import {
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {
  BrowserModule,
} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SchemaInterceptor } from './interceptors/schema.interceptor';
import { Router, RouterModule } from '@angular/router';
import localeEs from '@angular/common/locales/es';
import localeVe from '@angular/common/locales/es-VE';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SwiperModule } from 'swiper/angular';
import { GoogleMapsModule, GoogleMap } from '@angular/google-maps';
import { HomeComponent } from './pages/home/home.component';
import { VenuesComponent } from './pages/venues/venues.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductComponent } from './pages/product/product.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DisabledComponent } from './pages/disabled/disabled.component';
import { AppShellRenderDirective } from './directives/app-shell-render.directive';
import { AppShellNoRenderDirective } from './directives/app-shell-no-render.directive';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { SideFilterComponent } from './components/side-filter/side-filter.component';
import { CategoryFilterComponent } from './components/category-filter/category-filter.component';
import { SortFilterComponent } from './components/sort-filter/sort-filter.component';
import { ShoppingBagComponent } from './components/shopping-bag/shopping-bag.component';
import { SearchComponent } from './components/search/search.component';
import { ScheduleAlertComponent } from './components/schedule-alert/schedule-alert.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FeaturedCategoryCarouselComponent } from './components/featured-category-carousel/featured-category-carousel.component';
import { CarouselProductCardComponent } from './components/carousel-product-card/carousel-product-card.component';
import { SwiperDirective } from './directives/swiper.directive';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { CustomNavigationComponent } from './components/custom-navigation/custom-navigation.component';
// register Swiper custom elements
register();
registerLocaleData(localeEs);
registerLocaleData(localeVe);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VenuesComponent,
    ProductsComponent,
    ProductComponent,
    ContactComponent,
    DisabledComponent,
    AppShellRenderDirective,
    AppShellNoRenderDirective,
    SwiperDirective,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    ProductCardComponent,
    SideFilterComponent,
    CategoryFilterComponent,
    SortFilterComponent,
    ShoppingBagComponent,
    SearchComponent,
    ScheduleAlertComponent,
    SpinnerComponent,
    FeaturedCategoryCarouselComponent,
    CarouselProductCardComponent,
    CustomNavigationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    LayoutModule,
    RouterModule,
  ],
  providers: [
    GoogleMap,
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: SchemaInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
