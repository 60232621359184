import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TransferState, makeStateKey } from '@angular/platform-browser';

@Injectable()
export class SchemaInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject('schema_id') private schema: number,
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly transferState: TransferState
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const storeKey = makeStateKey<string>('schema');
    request = request.clone({
      headers: request.headers.append(
        'Authorization',
        this.transferState.get(storeKey, 'defaultValue')
      ),
    });
    return next.handle(request);
  }
}
