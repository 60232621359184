import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as styler from 'src/app/utils/styler';
import { Category } from 'src/app/models/category';

@Component({
  selector: 'app-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss'],
})
export class SortFilterComponent implements OnInit {
  styler = styler;
  sortOpen = true;
  cid: string = '';
  sort: string = '';
  @Output() close = new EventEmitter<void>();
  constructor(private route: ActivatedRoute) {}
  @Input() categories!: Category[];
  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      this.cid = params['cid'] ? params['cid'] : '';
      this.sort = params['sort'] ? params['sort'] : 'default';
    });
  }
  ngAfterViewInit() {
    this.styler.setPanelInitialState('sort');
  }
}
