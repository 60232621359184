import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import { SwiperOptions } from 'swiper';

@Directive({
  selector: '[swiperElement]',
})
export class SwiperDirective implements AfterViewInit {
  private readonly swiperElement: HTMLElement;

  @Input('config')
  config?: SwiperOptions;

  constructor(
    private el: ElementRef<HTMLElement>,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit() {
    if (platformBrowser(this.platformId)) {
      Object.assign(this.el.nativeElement, this.config);
      // @ts-ignore
      this.el.nativeElement.initialize();
    }
  }
}
