import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import * as dayjs from 'dayjs';
import { Timetable } from 'src/app/models/timetable';
import { LocalService } from 'src/app/services/local.service';
import { Business } from 'src/app/models/business';
import { Settings } from 'src/app/models/settings';

@Component({
  selector: 'app-schedule-alert',
  templateUrl: './schedule-alert.component.html',
  styleUrls: ['./schedule-alert.component.scss'],
})
export class ScheduleAlertComponent implements OnInit {
  timeTables!: Timetable[];
  currentDay: number = dayjs().day();
  schedule: string = '';
  business!: Business;
  settings!: Settings;
  inSchedule = false;
  willOpen: boolean = false;
  openHour: string = '';
  show = false;

  constructor(private api: ApiService, private local: LocalService) {}

  ngOnInit(): void {
    this.getSchedule();
    this.business = this.local.getValue('business');
    this.settings = this.local.getValue('settings');
  }

  async getSchedule() {
    try {
      const inventory = this.local.getValue('inventory');
      const { open, will_open, open_hour } = await this.api.getStatusSchedule(
        inventory.id
      );
      this.inSchedule = open;
      this.willOpen = will_open;
      this.openHour = open_hour;
      this.timeTables = await this.api.getTimeTables(
        this.local.getValue('inventory').id
      );
      const timetable = this.timeTables.find(
        (item) => item.day === this.currentDay
      );
      if (timetable) {
        this.show = true;
        this.setScheduleMessage(timetable);
      } else {
        this.show = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  setScheduleMessage(timetable: Timetable) {
    if (this.business.paused) {
      this.schedule = 'No estamos aceptando pedidos por los momentos';
      this.inSchedule = false;
    } else {
      if (this.inSchedule) {
        if (timetable.schedules.length > 1 && timetable.schedules.length <= 2) {
          this.schedule =
            'Abierto de ' +
            this.transformHour(timetable.schedules[0].openTime) +
            ' a ' +
            this.transformHour(timetable.schedules[0].closeTime) +
            ' y de ' +
            this.transformHour(timetable.schedules[1].openTime) +
            ' a ' +
            this.transformHour(timetable.schedules[1].closeTime);
        } else {
          this.schedule =
            'Abierto de ' +
            this.transformHour(timetable.schedules[0].openTime) +
            ' a ' +
            this.transformHour(timetable.schedules[0].closeTime);
        }
      } else {
        if (this.willOpen) {
          if (this.settings.scheduledOrdersDisabled) {
            this.schedule =
              'Estamos cerrados. Hora de apertura: ' +
              this.transformHour(this.openHour);
          } else {
            this.schedule =
              'Estamos cerrados, puedes programar tu pedido. Hora de apertura: ' +
              this.transformHour(this.openHour);
          }
        } else {
          if (this.settings.scheduledOrdersDisabled) {
            this.schedule = 'Cerrados por hoy';
          } else {
            this.schedule = 'Estamos cerrados, puedes programar tu pedido';
          }
        }
      }
    }
  }

  transformHour(time: string) {
    let transformedTime: string;
    transformedTime = time;
    transformedTime = transformedTime.substring(0, 5);
    let meridian: string;
    let timeValue = parseInt(transformedTime.substring(0, 2));
    if (timeValue < 12) {
      meridian = ' AM';
    } else {
      meridian = ' PM';
      timeValue = timeValue - 12;
    }
    if (timeValue === 0) {
      timeValue = 12;
    }
    let minutes = time.substring(3, 5);
    return timeValue + ':' + minutes + meridian;
  }
}
