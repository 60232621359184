import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Category } from 'src/app/models/category';
import * as styler from 'src/app/utils/styler';
@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
})
export class CategoryFilterComponent implements OnInit {
  styler = styler;
  categoryOpen = true;
  cid: string = '';
  sort: string = '';
  @Output() close = new EventEmitter<void>();

  constructor(private route: ActivatedRoute) {}
  @Input() categories!: Category[];
  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      this.cid = params['cid'] ? params['cid'] : '';
      this.sort = params['sort'] ? params['sort'] : 'default';
    });
  }
  ngAfterViewInit() {
    this.styler.setPanelInitialState('category');
  }
}
