import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Business } from "src/app/models/business";
import { Category } from "src/app/models/category";
import { Inventory } from "src/app/models/inventory";
import { RouteItem } from "src/app/models/route";
import { ShoppingBag } from "src/app/models/shopping-bag";
import { SupportContact } from "src/app/models/support-contact";
import { LocalService } from "src/app/services/local.service";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  animateClose = false;
  productsOpen = true;
  inventories!: Inventory[];
  selectedInventory!: Inventory;
  @Output() closeEmit = new EventEmitter<void>();
  @Input() categories!: Category[];
  @Input() contact!: SupportContact;
  @Input() business!: Business;
  @Input() routes!: RouteItem[];
  shoppingBag!: ShoppingBag;
  selectedRoute!: RouteItem | undefined;
  backRoute!: RouteItem | undefined;

  constructor(
    private local: LocalService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadLocalData();
  }

  closeMenu(): void {
    this.animateClose = true;
    setTimeout(() => {
      this.closeEmit.emit();
    }, 250);
  }

  setRoute(route: RouteItem) {
    if (route.items.length > 0) {
      this.backRoute = { ...this.selectedRoute } as RouteItem;
      this.selectedRoute = route;
    } else {
      this.navigateRoute(route);
      this.closeMenu();
    }
  }

  navigateRoute(route: RouteItem) {
    if (route.type === "CATEGORY") {
      this.router.navigate(["/productos"], {
        queryParams: { cid: route.link },
      });
      this.closeMenu();
    } else {
      window.location.href = route.link;
    }
  }

  goBackRoute(route: RouteItem) {
    if (
      this.backRoute?.name === this.selectedRoute?.name ||
      this.backRoute === undefined ||
      Object.keys(this.backRoute).length === 0
    )
      this.selectedRoute = undefined;
    else this.selectedRoute = { ...this.backRoute } as RouteItem;
  }

  inventoryChange(inventory: Inventory) {
    this.local.setValue("inventory", {
      ...inventory,
      timestamp: Date.now(),
    });
    this.shoppingBag = this.local.getValue("shoppingBag") as ShoppingBag;
    if (this.shoppingBag.orders.length) this.emptyCart();
    window.location.reload();
  }

  emptyCart() {
    this.shoppingBag.orders = [];
    this.local.setValue("shoppingBag", this.shoppingBag);
    this.local.updateQuantitySubject();
    this.local.updateCartSubject();
  }

  loadLocalData() {
    this.inventories = this.local.getValue("inventories");
    if (this.inventories)
      this.selectedInventory = this.inventories.filter(
        (inv) => inv.id === this.local.getValue("inventory").id,
      )[0];
  }
}
