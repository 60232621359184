import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { Design } from "src/app/models/design";
import { Inventory } from "src/app/models/inventory";
import { ApiService } from "src/app/services/api.service";
import { LocalService } from "src/app/services/local.service";
import { NotyfService } from "src/app/services/notyf.service";
import { ThemeService } from "src/app/services/theme.service";
import distance from "src/app/utils/distanceFunction";

@Component({
  selector: "app-venues",
  templateUrl: "./venues.component.html",
  styleUrls: ["./venues.component.scss"],
})
export class VenuesComponent implements OnInit {
  inventories: Inventory[] = [];
  loading: boolean = true;
  locationServiceDisabled: boolean = false;
  design: Design = this.theme.getCachedTheme();
  selectedInventory!: Inventory | null;

  constructor(
    private api: ApiService,
    private router: Router,
    private local: LocalService,
    private theme: ThemeService,
    private notyf: NotyfService,
  ) {}

  ngOnInit(): void {
    document
      .getElementById("header-logo")
      ?.addEventListener("complete", () => {});
    // this.loadLocalData();
    this.loadAsyncData();
  }

  // loadLocalData() {
  //   this.design = this.theme.getCachedTheme();
  // }
  async loadAsyncData() {
    try {
      this.inventories = await this.api.getActiveInventories();
      for (const inv of this.inventories) {
        inv.delivery_zones = [];
      }
      this.selectedInventory = null;
      this.loading = false;
    } catch (error) {
      this.notyf.error(error);
      this.loading = false;
    }
  }
  routeCheck() {
    if (this.inventories.length <= 1) {
      this.router.navigate(["/"]);
    }
  }

  getPosition() {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.pickNearest(position);
      },
      (error: any) => {
        // this.permissionDenied = true;
        // this.getInventories();
        if (error.code === 1) {
          this.locationServiceDisabled = true;
        }
      },
    );
  }

  selectVenue(inventory: Inventory) {
    this.local.setValue("inventory", {
      ...inventory,
      timestamp: Date.now(),
    });
    this.router.navigate(["/"]);
  }

  async pickNearest(currentLocation: GeolocationPosition) {
    try {
      const closerInventory = this.inventories.reduce(
        (selected, currentInventory) => {
          const distanceWithSelected = distance(
            currentLocation.coords.latitude,
            currentLocation.coords.longitude,
            selected.latitude,
            selected.longitude,
          );
          const distanceWithCurrentInventory = distance(
            currentLocation.coords.latitude,
            currentLocation.coords.longitude,
            currentInventory.latitude,
            currentInventory.longitude,
          );
          if (distanceWithSelected > distanceWithCurrentInventory) {
            return currentInventory;
          } else {
            return selected;
          }
        },
      );
      this.selectStore(closerInventory);
    } catch (error) {
      this.notyf.error(error);
    }
  }
  selectStore(inventory: Inventory) {
    this.local.setValue("inventory", {
      ...inventory,
      timestamp: Date.now(),
    });
    this.router.navigate(["/"]);
  }
}
