<div class="page h-full w-full items-center justify-center gap-4 overflow-y-auto p-10" *ngIf="!loading">
    <img [src]="design.logo_url" alt="Logo" class="h-[150px] w-[150px] object-contain">
    <span class="text-xl text-secondary text-center">La tienda online no se encuentra disponible.</span>
    <!-- <span class="text-base text-secondary text-center" *ngIf="contacts.length > 0">
        Para mas información comunícate directamente con nosotros
    </span>
    <ng-container *ngFor="let contact of contacts">
        <a [href]="'https://wa.me/' + contact?.phone_number" target="_blank" *ngIf="contact.phone_number"
            class="text-secondary underline text-center">{{contact.Inventory.name}} {{contact.phone_number}}</a>
    </ng-container> -->
</div>
<div class="page h-full w-full items-center justify-center" *ngIf="loading">
    <div class="h-16 w-16">
        <app-spinner [color]="'secondary'"></app-spinner>
    </div>
</div>