import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Business } from '../models/business';
import { Category } from '../models/category';
import { Customer } from '../models/customer';
import { Design } from '../models/design';
import { Inventory } from '../models/inventory';
import { Order } from '../models/order';
import { PaymentLink } from '../models/payment-link';
import { PaymentSystem } from '../models/payment-system';
import { Product } from '../models/product';
import { Sale } from '../models/sale';
import { Settings } from '../models/settings';
import { SupportContact } from '../models/support-contact';
import { Timetable } from '../models/timetable';
import { Route } from '../models/route';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = environment.baseUrl;
  featuredCategory = {} as Category;
  featuredCategorySubject = new Subject<void>();
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  // PaymentLinks
  async createPaymentLink(link: PaymentLink): Promise<any> {
    return firstValueFrom(
      this.http.post<PaymentLink>(this.baseUrl + 'payment-link', link)
    );
  }

  // Products
  async getProduct(id: string, InventoryId: number): Promise<Product> {
    return firstValueFrom(
      this.http.get<Product>(this.baseUrl + 'product/' + id, {
        params: {
          InventoryId: InventoryId.toString(),
        },
      })
    );
  }

  async getProducts(
    size: number,
    page: number,
    inventoryId: number,
    filter: string,
    sort: string,
    category_ref?: any
  ): Promise<any> {
    if (isPlatformBrowser(this.platformId)) {
      return firstValueFrom(
        this.http.get<any>(this.baseUrl + 'product/paginated/inventory', {
          params: category_ref
            ? {
                size: size.toString(),
                page: page.toString(),
                filter,
                InventoryId: inventoryId.toString(),
                sort,
                category_ref,
              }
            : {
                size: size.toString(),
                page: page.toString(),
                filter,
                sort,
                InventoryId: inventoryId.toString(),
              },
        })
      );
    } else {
      return [];
    }
  }

  async searchProducts(text: string, inventoryId: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(this.baseUrl + 'product/search', {
        text,
        InventoryId: inventoryId.toString(),
      })
    );
  }

  async checkAvailableProducts(
    orders: Order[],
    inventoryId: number
  ): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(this.baseUrl + 'product-inventory/active/check', {
        orders,
        InventoryId: inventoryId,
      })
    );
  }

  // // Categories
  // async getCategories(id: number): Promise<Category[]> {
  //   return firstValueFrom(
  //     this.http.get<Category[]>(
  //       this.baseUrl + 'category/product/inventory/' + id
  //     )
  //   );
  // }

  async getCategory(id: string): Promise<Category> {
    return firstValueFrom(
      this.http.get<Category>(this.baseUrl + 'category/' + id)
    );
  }

  async getCategories(id: number): Promise<Category[]> {
    return firstValueFrom(
      this.http.get<Category[]>(
        this.baseUrl + 'category/product/inventory/' + id
      )
    );
  }

  async getExtrasCategories(): Promise<Category[]> {
    return firstValueFrom(
      this.http.get<Category[]>(this.baseUrl + 'category/extra/get')
    );
  }

  // Business
  async getBusiness(): Promise<Business> {
    return firstValueFrom(this.http.get<Business>(this.baseUrl + 'business/1'));
  }

  // Design
  async getDesign(): Promise<Design[]> {
    return firstValueFrom(this.http.get<Design[]>(this.baseUrl + 'design'));
  }

  // Inventories
  async getActiveInventories(): Promise<Inventory[]> {
    return firstValueFrom(
      this.http.get<Inventory[]>(this.baseUrl + 'inventory/filter/active')
    );
  }

  //delete
  async getInventory(inventoryId: number): Promise<Inventory> {
    return firstValueFrom(
      this.http.get<Inventory>(this.baseUrl + 'inventory/' + inventoryId)
    );
  }

  //Schedules
  async getTimeTables(inventoryId: number): Promise<Timetable[]> {
    return firstValueFrom(
      this.http.get<Timetable[]>(this.baseUrl + 'schedule/' + inventoryId)
    );
  }

  async checkSchedule(saleSchedule: Date, inventoryId: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(this.baseUrl + 'schedule/check/' + inventoryId, {
        saleSchedule,
      })
    );
  }

  async getStatusSchedule(inventoryId: number) {
    return firstValueFrom(
      this.http.get<any>(this.baseUrl + 'schedule/status/' + inventoryId)
    );
  }

  // // Customer
  // async getCustomerByWink(id: number): Promise<Customer> {
  //   return firstValueFrom(
  //     this.http.get<Customer>(this.baseUrl + 'customer/find/' + id)
  //   );
  // }
  //delete

  async getCustomer(id: number): Promise<Customer> {
    return firstValueFrom(
      this.http.get<Customer>(this.baseUrl + 'customer/' + id)
    );
  }

  // async addCustomer(id: number): Promise<void> {
  //   return firstValueFrom(
  //     this.http.post<void>(this.baseUrl + 'customer/add', { user_id: id })
  //   );
  // }
  // async updateCustomerEmail(email: string, user_id: number): Promise<any> {
  //   return firstValueFrom(
  //     this.http.put(this.baseUrl + 'customer/update/email', {
  //       user_id,
  //       email,
  //     })
  //   );
  // }

  // async updateCustomerStripe(id: number, customer: string): Promise<any> {
  //   return firstValueFrom(
  //     this.http.post(this.baseUrl + 'customer/stripe/update', {
  //       id,
  //       customer,
  //     })
  //   );
  // }
  // // Settings
  // delete
  async getSettings(inventory_id: number): Promise<Settings> {
    return firstValueFrom(
      this.http.get<Settings>(this.baseUrl + 'settings/' + inventory_id)
    );
  }

  async getAllSettings(): Promise<Settings[]> {
    return firstValueFrom(this.http.get<Settings[]>(this.baseUrl + 'settings'));
  }

  // // SupportContact
  async getSupportContactOfInventory(
    inventoryId: number
  ): Promise<SupportContact> {
    return firstValueFrom(
      this.http.get<SupportContact>(
        this.baseUrl + 'support-contact/inventory/' + inventoryId
      )
    );
  }

  // // PaymentSystems
  // async getAllPaymentSystems(): Promise<PaymentSystem[]> {
  //   return firstValueFrom(
  //     this.http.get<PaymentSystem[]>(this.baseUrl + 'payment-system/all')
  //   );
  // }
  // delete
  async getPaymentSystems(
    inventoryId: number,
    active: boolean
  ): Promise<PaymentSystem[]> {
    return firstValueFrom(
      this.http.get<PaymentSystem[]>(this.baseUrl + 'payment-system/', {
        params: {
          InventoryId: inventoryId.toString(),
          active: active.toString(),
        },
      })
    );
  }

  // // Discount
  // async findDiscountByCode(code: string): Promise<Discount> {
  //   return firstValueFrom(
  //     this.http.post<Discount>(this.baseUrl + 'discount/find', { code })
  //   );
  // }

  // async updateDiscount(d: Discount): Promise<any> {
  //   return firstValueFrom(this.http.put(this.baseUrl + 'discount/update', d));
  // }

  // // Discount Log
  // async createDiscountLog(
  //   customerId: number,
  //   discountRef: string
  // ): Promise<any> {
  //   return firstValueFrom(
  //     this.http.post(this.baseUrl + 'discount-log/add', {
  //       CustomerId: customerId,
  //       discount_ref: discountRef,
  //     })
  //   );
  // }

  // async findDiscountLog(customerId: number, discountRef: string): Promise<any> {
  //   return firstValueFrom(
  //     this.http.post(this.baseUrl + 'discount-log/find', {
  //       CustomerId: customerId,
  //       discount_ref: discountRef,
  //     })
  //   );
  // }

  // // Sale
  //delete
  async getSale(id: number): Promise<Sale> {
    return firstValueFrom(this.http.get<Sale>(this.baseUrl + 'sale/' + id));
  }

  // async getIfFirstPurchase(customerId: number): Promise<any> {
  //   return firstValueFrom(
  //     this.http.get(this.baseUrl + `sale/firstPurchase/${customerId}`)
  //   );
  // }

  // async createSale(data: any): Promise<any> {
  //   return firstValueFrom(this.http.post(this.baseUrl + 'sale/add', data));
  // }

  async getRoutes(): Promise<Route[]> {
    return firstValueFrom(
      this.http.get<Route[]>(this.baseUrl + 'route/active/get')
    );
  }

  async checkStock(orders: Order[]): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(this.baseUrl + 'variant/stock', {
        orders,
      })
    );
  }
}
