import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { LocalService } from 'src/app/services/local.service';
import { Timetable } from 'src/app/models/timetable';
import { ApiService } from 'src/app/services/api.service';
import { Inventory } from 'src/app/models/inventory';
import { ThemeService } from 'src/app/services/theme.service';
import { Design } from 'src/app/models/design';

interface IMarker {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  center: IMarker = { lat: 6.766656839278399, lng: -66.01753837936012 };
  markerOptions: Object = { draggable: false };
  markerPosition = { lat: 0, lng: 0 };
  inventory!: Inventory;
  timeTables!: Timetable[];
  currentDay: number = dayjs().day();
  design!: Design;
  mapOptions: google.maps.MapOptions = {
    zoom: 16,
    gestureHandling: 'none',
    disableDefaultUI: true,
    styles: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
  };

  constructor(
    private local: LocalService,
    private api: ApiService,
    private theme: ThemeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    document
      .getElementById('banner-image')
      ?.addEventListener('complete', () => {});
    this.inventory = this.local.getValue('inventory');
    this.center.lat = this.inventory.latitude;
    this.center.lng = this.inventory.longitude;
    this.markerPosition.lat = this.inventory.latitude;
    this.markerPosition.lng = this.inventory.longitude;
    this.loadLocalData();
    this.getSchedules();
  }

  locateMarker() {
    this.center.lat = this.inventory.latitude;
    this.center.lng = this.inventory.longitude;
    this.markerPosition.lat = this.inventory.latitude;
    this.markerPosition.lng = this.inventory.longitude;
    this.cd.detectChanges();
  }

  loadLocalData() {
    this.design = this.theme.getCachedTheme();
  }

  async getSchedules() {
    try {
      this.timeTables = await this.api.getTimeTables(
        this.local.getValue('inventory').id
      );
    } catch (error) {
      console.error(error);
    }
  }

  transformHour(time: string) {
    let transformedTime: string;
    transformedTime = time;
    transformedTime = transformedTime.substring(0, 5);
    let meridian: string;
    let timeValue = parseInt(transformedTime.substring(0, 2));
    if (timeValue < 12) {
      meridian = ' AM';
    } else {
      meridian = ' PM';
      timeValue = timeValue - 12;
    }
    if (timeValue === 0) {
      timeValue = 12;
    }
    let minutes = time.substring(3, 5);
    return timeValue + ':' + minutes + meridian;
  }

  transformDay(day: number) {
    let value: string = '';
    switch (day) {
      case 1: {
        value = 'Lunes';
        break;
      }
      case 2: {
        value = 'Martes';
        break;
      }
      case 3: {
        value = 'Miércoles';
        break;
      }
      case 4: {
        value = 'Jueves';
        break;
      }
      case 5: {
        value = 'Viernes';
        break;
      }
      case 6: {
        value = 'Sábado';
        break;
      }
      case 7: {
        value = 'Domingo';
        break;
      }
    }
    return value;
  }

  // ngOnDestroy(): void {
  //   window.google = {} as any;
  // }
}
