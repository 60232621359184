export const environment = {
  baseUrl: 'https://api.winktienda.com/',
  builderUrl: 'http://localhost:4200/admin/preview/',
  frontUrl: 'http://localhost:4200/',
  winkUrl: 'https://core.winktienda.com/api/',
  payUrl: 'https://checkout.winktienda.com/',
  winkStoreDomain: 'winktienda.com',
  mixpanelToken: '3b55961a42a037b96b3c79da0dacb356',
  stripePublicKey:
    'pk_live_51Ibr5sKN8BO7vayfBegODDzwtSFpI8WJCLQbGecu4DovWq5nvY6Xjcj991DKw7mSqFRuFwNHwstcD0Ae0CKmRvbq00C8k2zG7L',
  production: true,
  privateKey: 'v1v4w1nkm1p4n4-xXx-42069',
  firebase: {
    projectId: 'wink-309217',
    appId: '1:747450071341:web:502f51fe901db054bffba4',
    storageBucket: 'wink-309217.appspot.com',
    locationId: 'us-east1',
    apiKey: 'AIzaSyCKVBvM-HPQr4MYT8xcAtW2fxGbZD73KhY',
    authDomain: 'wink-309217.firebaseapp.com',
    messagingSenderId: '747450071341',
    measurementId: 'G-1S9M2RV4YX',
  },
  mapsApiKey: 'AIzaSyAJaSIQbyNT_Xwg2MzddP_iRaFVgbxu9-M',
  sentryDsn:
    'https://4609d4a3ce2f41dca1240a0542e213c4@o1253845.ingest.sentry.io/4504612694130688',
};
