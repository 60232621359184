<div [ngClass]="{
    'animate-filterClose': animateClose,
    'animate-filterOpen': !animateClose
  }"
  class="flex flex-col w-full real-h fixed max-w-full sm:max-w-[400px] top-0 right-0  z-[70] bg-white !rounded-none !p-0 !overflow-y-auto">

  <div class="flex justify-between items-center p-8">
    <span class=" text-2xl font-bold">Filtrar y ordenar</span>
    <button (click)="closeMenu()" class="bg-transparent text-primary text-2xl">
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="flex flex-col  gap-10 p-8">
    <app-category-filter (close)="closeMenu()" [categories]="categories"></app-category-filter>
    <app-sort-filter (close)="closeMenu()" [categories]="categories"></app-sort-filter>

  </div>


</div>
<div (click)="closeMenu()" [ngClass]="{
  'animate-overlayClose': animateClose,
  'animate-overlay': !animateClose
}" class="h-[100vh] w-full absolute z-[60] bg-black opacity-40 top-0"></div>