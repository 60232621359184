//Este es un utils enfocado en manejar todo el styling dinamico basado en logica con el DOM 👀

export const toggleExtraCategoryError = (category_ref: string) => {
  document.getElementById(category_ref)?.classList.add('!border-red-400');
  document.getElementById(category_ref + '-arrow')?.classList.add('hidden');
  document.getElementById(category_ref + '-info')?.classList.add('block');
  document.getElementById(category_ref + '-info')?.classList.remove('hidden');
  document.getElementById(category_ref)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });
  document.getElementById(category_ref)?.classList.add('animate-wiggle');
  setTimeout(() => {
    document.getElementById(category_ref)?.classList.remove('animate-wiggle');
  }, 500);
};
// toggle de las categorias de extras para que abran y cierren
export const toggleExtraCategory = (category_ref: string) => {
  let panel = document.getElementById(category_ref + '-panel');
  let arrow = document.getElementById(category_ref + '-arrow');
  const isOpen = panel?.classList.contains('open');
  if (isOpen) {
    //cerrar
    panel?.classList.remove('open');
    panel?.classList.add('closed');
    arrow!.style.transform = 'rotate(-90deg)';
    panel!.style.maxHeight = '0';
    panel!.style.height = '0';
  } else {
    //abrir
    panel?.classList.add('open');
    panel?.classList.remove('closed');
    arrow!.style.transform = 'rotate(0deg)';
    panel!.style.maxHeight = panel!.scrollHeight + 'px';
    panel!.style.height = panel!.scrollHeight + 'px';
  }
};

//toggle a la descripcion para que la corte o la muestre completa
export const toggleDescription = () => {
  let description = document.getElementById('description');
  if (parseInt(description!.style.maxHeight) > 100) {
    //cerrar
    description!.style.maxHeight = '100px';
    description!.style.height = '100px';
    description?.classList.add('inner-shadow');
  } else {
    //abrir
    description!.style.maxHeight = description!.scrollHeight + 'px';
    description!.style.height = description!.scrollHeight + 'px';
    description?.classList.remove('inner-shadow');
  }
};
//toggle filter
export const toggleFilter = (elementId: string) => {
  let panel = document.getElementById(elementId + '-panel');
  let arrow = document.getElementById(elementId + '-arrow');
  if (parseInt(panel!.style.height) > 0) {
    //cerrar
    arrow!.style.transform = 'rotate(0deg)';
    panel!.style.height = '0';
  } else {
    //abrir
    arrow!.style.transform = 'rotate(90deg)';
    panel!.style.height = panel!.scrollHeight + 'px';
  }
};

export const setPanelInitialState = (elementId: string) => {
  let arrow = document.getElementById(elementId + '-arrow');
  let panel = document.getElementById(elementId + '-panel');
  panel!.style.height = panel!.scrollHeight + 'px';
  arrow!.style.transform = 'rotate(90deg)';
};
