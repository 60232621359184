import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Category } from 'src/app/models/category';
@Component({
  selector: 'app-side-filter',
  templateUrl: './side-filter.component.html',
  styleUrls: ['./side-filter.component.scss'],
})
export class SideFilterComponent implements OnInit {
  animateClose = false;
  @Output() closeEmit = new EventEmitter<void>();
  @Input() categories!: Category[];
  ngOnInit(): void {}
  closeMenu(): void {
    this.animateClose = true;
    setTimeout(() => {
      this.closeEmit.emit();
    }, 250);
  }
}
